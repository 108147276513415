
import { Options, Vue } from 'vue-class-component'
import AdminDomains from '@/components/Domains/AdminDomains/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminDomains
  }
})
export default class DomainsView extends Vue {}
