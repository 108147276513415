import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
// import BaseFunctions from '@/components/Utility/Base'
import DomainsDataService from '@/services/DomainsDataService'
import SysDomain from '@/types/SysDomain'
import CommonFunctions from '@/components/Utility/Common'

/* export default defineComponent({
  name: 'AdminDomains',
  setup () {
    // component setup
  }
}) */

type domainDataType = { domainTitle: string; domainURL: string }
type dataReturnType = { domaenes: any; filterStatusStringValue: string; tabValue: string; index: number; error: any; }

@Options({
  props: {
    msg: String
  }
})
export default class AdminDomains extends Vue {
  msg!: string
  error: any = null
  private tabValue = 'Vis og rediger domæner'
  private domaenes: SysDomain[] = []
  private tempDomain = {} as SysDomain
  domainData: domainDataType = { domainTitle: '', domainURL: '' }
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktiv', 'Inaktiv']
  domainSearchInputValue = ''
  domainSearchTerm = ''
  toggleIconsSortingValue = 0
  private oldSort = ''
  private currentListSortingOrder = ''
  private submitted = false
  private editDomainModal = false
  private deleteDomainWarningModal = false
  private deleteDomainId = 0
  private lastEditedDomainId = 0
  private lastCreatedDomainId = 0
  private index = 0

  readonly name : string = 'AdminDomains'
  $Message: any

  data (): dataReturnType {
    return {
      domaenes: this.domaenes,
      filterStatusStringValue: this.filterStatusStringValue,
      tabValue: 'Vis og rediger domæner',
      index: this.index,
      error: this.error
    }
  }

  @Watch('deleteDomainWarningModal')
  onWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteDomainId = 0
    }
  }

  @Watch('filterStatusStringValue')
  onDomainStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveDomains()
  }

  @Watch('toggleIconsSortingValue')
  onListSortToggleChanged (toggleValue: string, oldToggleValue: string) : void {
    console.log('List sort toggle changed to: ' + toggleValue)
    if (toggleValue === 'check_box_outline_blank') {
      this.currentListSortingOrder = ''
    } else if (toggleValue === 'domaintitle') {
      this.currentListSortingOrder = 'domaene_titel:asc'
    } else if (toggleValue === 'domainfqdn') {
      this.currentListSortingOrder = 'domaene_url:asc'
    }

    this.retrieveDomains()
  }

  public forceRerenderDomainsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public findDomain () : void {
    console.log('Search domain.')
    this.domainSearchTerm = this.domainSearchInputValue
    this.retrieveDomains()
  }

  public editDomain (domainId: number) : void {
    console.log('Edit domain item with Id: ' + domainId)
    this.lastEditedDomainId = domainId

    DomainsDataService.get(domainId.toString())
      .then((response) => {
        this.tempDomain = response.data
        console.log(response.data)

        this.domainData = { domainTitle: this.tempDomain.domaene_titel, domainURL: this.tempDomain.domaene_url }
        this.editDomainModal = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelDomainEdit () : void {
    console.log('Cancel domain item edit.')
    this.editDomainModal = false
    this.domainData = { domainTitle: '', domainURL: '' }
  }

  public statusDomain (domainId: number) : void {
    console.log('Change status of domain item with Id: ' + domainId)

    DomainsDataService.get(domainId.toString())
      .then((response) => {
        this.tempDomain = response.data
        console.log(response.data)

        const updateDomainData = {
          domaene_status: !this.tempDomain.domaene_status
        }

        console.log('New status of domain item = ' + updateDomainData.domaene_status)

        DomainsDataService.update(domainId.toString(), updateDomainData)
          .then((response) => {
            // this.lastUpdatedDomainId = response.data.id
            console.log(response.data)
            const domainIndexToUpdateStatus = this.domaenes.findIndex(x => x.id === domainId)
            this.domaenes[domainIndexToUpdateStatus].domaene_status = updateDomainData.domaene_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteDomainWarning (domainId: number) : void {
    this.deleteDomainId = domainId
    this.deleteDomainWarningModal = true
  }

  public deleteDomain (domainId: number) : void {
    console.log('Delete domain with Id: ' + domainId)

    DomainsDataService.delete(domainId.toString())
      .then((response) => {
        console.log(response.data)
        this.retrieveDomains()
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public createDomain () : void {
    if (this.domainData.domainTitle.trim().length > 1 && this.domainData.domainURL.trim().length > 2) {
      const createDomainData = {
        domaene_titel: this.domainData.domainTitle,
        domaene_url: this.domainData.domainURL,
        domaene_status: true
      }

      console.log('Attempt to create new domain item with Id: ' + (1 + this.lastCreatedDomainId))

      DomainsDataService.create(createDomainData)
        .then((response) => {
          this.lastCreatedDomainId = response.data.id
          console.log(response.data)

          this.submitted = true
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
        .then(() => {
          this.$Message.success({ text: 'Domænet er oprettet' })
          this.newCreateDomain()
          this.tabValue = 'Vis og rediger domæner'
        })
      // this.domainData = { domainTitle: '', domainURL: '' }
    }
  }

  public newCreateDomain () : void {
    this.submitted = false
    this.domainData = { domainTitle: '', domainURL: '' }
    this.retrieveDomains()
  }

  public updateDomain () : boolean {
    console.log('Update information of domain item with Id: ' + this.lastEditedDomainId)

    if (this.domainData.domainTitle.trim().length > 1 && this.domainData.domainURL.trim().length > 2) {
      const updateDomainData = {
        domaene_titel: this.domainData.domainTitle,
        domaene_url: this.domainData.domainURL
      }

      DomainsDataService.update(this.lastEditedDomainId.toString(), updateDomainData)
        .then((response) => {
          // this.lastUpdateDomainId = response.data.id
          console.log(response.data)
          this.retrieveDomains()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      this.editDomainModal = false
      this.domainData = { domainTitle: '', domainURL: '' }
      return true
    }

    return false
  }

  public retrieveDomains () : void {
    console.log('Fetching domain data from API.')

    if (this.domainSearchTerm.length > 0) {
      DomainsDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktiv'), (this.filterStatusStringValue === 'Vis alt'), this.domainSearchTerm, this.currentListSortingOrder)
        .then((response) => {
          this.domaenes = response.data
          console.log(response.data)
          this.forceRerenderDomainsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        DomainsDataService.getAll(this.currentListSortingOrder)
          .then((response) => {
            this.domaenes = response.data
            console.log(response.data)
            this.forceRerenderDomainsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        DomainsDataService.findByDomainStatus((this.filterStatusStringValue === 'Aktiv'), this.currentListSortingOrder)
          .then((response) => {
            this.domaenes = response.data
            console.log(response.data)
            this.forceRerenderDomainsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveDomains()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
